import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Table, Card, Container, Row, Col } from "react-bootstrap";
import Tabs from 'react-bootstrap/Tabs';
import TabPane from 'react-bootstrap/TabPane';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tab = makeShortcode("Tab");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
  <Row className="px-4" mdxType="Row">
   <Col mdxType="Col">
          <p>{`The BeYond-COVID(2021–2024) project aimed to make COVID-19 data accessible to scientists in laboratories but also to anyone who can use it, such as medical staff in hospitals or government officials.`}</p>
          <span className="anchor" id="publications"></span>
          <h2>{`Publications`}</h2>
          <Container className="full-width" mdxType="Container">
            <Row className="mt-4" mdxType="Row">
     <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Dec 2023 | Plass M et al, New Biotechnology (2023)</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Provenance of specimen and data – A prerequisite for AI development in computational pathology</Card.Title>
            <Card.Text className="mb-5 mt-3">
            In this paper, a framework is presented for recording and publishing provenance information to meet these requirements. 
            </Card.Text>
            <div className="bottom-link"><a href="https://doi.org/10.1016/j.nbt.2023.09.006" aria-label="Read the paper in the ScienceDirect journal">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Oct 2023 | Meurisse M et al, BMC Med Res Methodol (2023)</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Federated causal inference based on real-world observational data sources: application to a SARS-CoV-2 vaccine effectiveness assessment</Card.Title>
            <Card.Text className="mb-5 mt-3">
            The framework provides a systematic approach to address federated cross-national policy-relevant causal research questions based on sensitive population, health and care data in a privacy-preserving and interoperable way. 
            </Card.Text>
            <div className="bottom-link"><a href="https://bmcmedresmethodol.biomedcentral.com/articles/10.1186/s12874-023-02068-3" aria-label="Read the paper in the BMC">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
  </Row>
            <Row className="mt-4" mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Oct 2023</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Preparedness Data Hub</Card.Title>
            <Card.Text className="mb-5 mt-3">
            The system is intended to develop the tools (technical implementation) to allow the rapid deployment and configuration of a disease X scenario preparedness Data Hub.
            </Card.Text>
            <div className="bottom-link"><a href="https://zenodo.org/records/10069940" aria-label="Read the paper in the Zenodo">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
       <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Sep 2023 | Cannas A et al, Microorganisms (2023)</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Epidemiological and molecular investigation of the heater–cooler unit (HCU)-related outbreak of invasive mycobacterium chimaera infection occurred in Italy</Card.Title>
            <Card.Text className="mb-5 mt-3">
            Here, we report the results of the epidemiological and molecular investigations conducted in Italy after the alarm raised about this epidemic event. 
            </Card.Text>
            <div className="bottom-link"><a href="https://www.mdpi.com/2076-2607/11/9/2251" aria-label="Read the paper in the MDPI">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
  </Row>
            <Row className="mt-4" mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Sep 2023 | David, Romain, et al. CODATA Data Science Journal 22 (2023)</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Umbrella Data Management Plans to integrate FAIR data : lessons from the ISIDORe and BY-COVID consortia for pandemic preparedness</Card.Title>
            <Card.Text className="mb-5 mt-3">
            Research data can have enduring value, as long as scientists can use, reuse and combine data sets.
            </Card.Text>
            <div className="bottom-link"><a href="https://datascience.codata.org/articles/10.5334/dsj-2023-035" aria-label="Read the paper in the data science journal">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Sep 2023 | David, Romain, et al. CODATA Data Science Journal 22 (2023)</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">"Be Sustainable", Recommendations for FAIR Resources in Life Sciences research: EOSC-Life's Lessons</Card.Title>
            <Card.Text className="mb-5 mt-3">
            LifeScience(LS) communities cover multiple scientific domains and carry out a diversity of research, from basic biological studies to applied epidemiological and environmental investigations.
            </Card.Text>
            <div className="bottom-link"><a href="https://zenodo.org/record/8338931" aria-label="Read the paper in Zenodo">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
  </Row>
            <Row className="mt-4" mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Aug 2023 | Catalano A et al, Viruses (2023)</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Antibiotic-Resistant ESKAPE Pathogens and COVID-19: The Pandemic beyond the Pandemic</Card.Title>
            <Card.Text className="mb-5 mt-3">
            The aim of this review is to highlight the state of the art of antibacterial resistance worldwide, focusing on the most important pathogens, namely Enterobacterales, Acinetobacter baumannii, and Klebsiella pneumoniae, and their resistance to the most common antibiotics.
            </Card.Text>
            <div className="bottom-link"><a href="https://doi.org/10.3390%2Fv15091843" aria-label="Read the paper in open science">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">July 2023 | Pipek O et al, Preprint (2023)</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Systematic detection of co-infection and intra-host recombination in more than 2 million global SARS-CoV-2 samples</Card.Title>
            <Card.Text className="mb-5 mt-3">
             Here we present a comprehensive analysis of more than 2 million SARS-CoV-2 raw read datasets submitted to the European COVID-19 Data Portal to identify coinfections and intra-host recombination.
            </Card.Text>
            <div className="bottom-link"><a href="https://assets.researchsquare.com/files/rs-3159433/v1/59d366f4-0f0e-44fd-82a2-c72488b25c9b.pdf" aria-label="Read the paper in research square">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
  </Row>
  <Row className="mt-4" mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">June 2023 | Kemmer et al., Histochemistry and Cell Biology</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Building a FAIR image data ecosystem for microscopy communities</Card.Title>
            <Card.Text className="mb-5 mt-3">
            We outline a wide range of efforts and solutions currently being developed by the microscopy community to address these challenges on the path towards FAIR bioimaging data. 
            </Card.Text>
            <div className="bottom-link"><a href="https://onlinelibrary.wiley.com/doi/10.1002/lrh2.10365" aria-label="Read the paper in online library">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Apr 2023 | Karki et al., Bioinformatics advances</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Mpox Knowledge Graph: a comprehensive representation embedding chemical entities and associated biology of Mpox</Card.Title>
            <Card.Text className="mb-5 mt-3">
            Using Knowledge Graph (KG) representations we have depicted chemical and biological aspects of MPXV. 
            </Card.Text>
            <div className="bottom-link"><a href="https://academic.oup.com/bioinformaticsadvances/article/3/1/vbad045/7100349" aria-label="Read the paper in the Oxford academic">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
  </Row>
            <Row className="mt-4" mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Sep 2023 | David, Romain, et al. CODATA Data Science Journal 22 (2023)</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Umbrella Data Management Plans to integrate FAIR data : lessons from the ISIDORe and BY-COVID consortia for pandemic preparedness</Card.Title>
            <Card.Text className="mb-5 mt-3">
            Research data can have enduring value, as long as scientists can use, reuse and combine data sets.
            </Card.Text>
            <div className="bottom-link"><a href="https://datascience.codata.org/articles/10.5334/dsj-2023-035" aria-label="Read the paper in the data science journal">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Sep 2023 | David, Romain et al. Zenodo preprint (2023)</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">"Be Sustainable", Recommendations for FAIR Resources in Life Sciences research: EOSC-Life's Lessons</Card.Title>
            <Card.Text className="mb-5 mt-3">
            LifeScience(LS) communities cover multiple scientific domains and carry out a diversity of research, from basic biological studies to applied epidemiological and environmental investigations.
            </Card.Text>
            <div className="bottom-link"><a href="https://zenodo.org/record/8338931" aria-label="Read the paper in zenodo">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
  </Row>
  <Row className="mt-4" mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">June 2023 | Kemmer et al., Histochemistry and Cell Biology</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Building a FAIR image data ecosystem for microscopy communities</Card.Title>
            <Card.Text className="mb-5 mt-3">
            We outline a wide range of efforts and solutions currently being developed by the microscopy community to address these challenges on the path towards FAIR bioimaging data. 
            </Card.Text>
            <div className="bottom-link"><a href="https://onlinelibrary.wiley.com/doi/10.1002/lrh2.10365" aria-label="Read the paper in online library">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Apr 2023 | Karki et al., Bioinformatics advances</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Mpox Knowledge Graph: a comprehensive representation embedding chemical entities and associated biology of Mpox</Card.Title>
            <Card.Text className="mb-5 mt-3">
            Using Knowledge Graph (KG) representations we have depicted chemical and biological aspects of MPXV. 
            </Card.Text>
            <div className="bottom-link"><a href="https://academic.oup.com/bioinformaticsadvances/article/3/1/vbad045/7100349" aria-label="Read the paper in the Oxford academic">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
  </Row>
            <Row className="mt-4" mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Apr 2023 | Wittner et al., Learning Health Systems</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Toward a common standard for data and specimen provenance in life sciences</Card.Title>
            <Card.Text className="mb-5 mt-3">
            We present our effort to provide trustworthy machine-actionable documentation of the data lineage and specimens. Experts from the biotechnology and biomedical fields are invited to further contribute to the standard.
            </Card.Text>
            <div className="bottom-link"><a href="https://doi.org/10.1002/lrh2.10365" aria-label="Read the paper in Wiley online library">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Apr 2023 | Chiara et al., Communications Biology</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">HaploCoV: unsupervised and rapid variant detection</Card.Title>
            <Card.Text className="mb-5 mt-3">
            HaploCoV enables the exploration of SARS-CoV-2 genomic diversity through space and time, to identify novel emerging viral variants and prioritize variants of potential epidemiological interest in a rapid and unsupervised manner.
            </Card.Text>
            <div className="bottom-link"><a href="https://www.nature.com/articles/s42003-023-04784-4" aria-label="Read the paper in nature">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
  </Row>
            <Row className="mt-4" mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Apr 2023 | Braukmann, Zenodo</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Enabling FAIR data in the Dutch SSH community</Card.Title>
            <Card.Text className="mb-5 mt-3">
            A presentation given at the BY-COVID Spring 23 Use Cases Workshop: Integration of socioeconomic data in observational studies on vaccine effectiveness about the DANS Data Station Social Sciences and Humanities (SSH).
            </Card.Text>
            <div className="bottom-link"><a href="https://zenodo.org/record/7867780" aria-label="Read the paper in Zenodo">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
            <Card.Subtitle className="text-black-50">Nov 2022 | Conner et al., bioRxiv preprint</Card.Subtitle>
            <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Towards increased accuracy and reproducibility in SARS-CoV2 sequence analysis</Card.Title>
            <Card.Text className="mb-5 mt-3">
            We examine the impact of sequencing technologies (Illumina and Oxford Nanopore) and 7 different downstream bioinformatic protocols on SARSCoV-2 variant calling as part of the NIH Accelerating COVID-19 Therapeutic Interventions and Vaccines (ACTIV) Tracking Resistance and Coronavirus Evolution (TRACE) initiative.
            </Card.Text>
            <div className="bottom-link"><a href="https://doi.org/10.1101/2022.11.03.515010" aria-label="Read the paper in bioRxiv">
            Read more
            </a></div>
          </Card.Body>
      </Card>
    </Col>
  </Row>
  <Row className="mt-4" mdxType="Row">
  <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
          <Card.Subtitle className="text-black-50">Nov 2022 | Mentes et al., Scientific Reports</Card.Subtitle>
          <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Identification of mutations in SARS-CoV-2 PCR primer regions</Card.Title>
          <Card.Text className="mb-5 mt-3">
           We propose an analysis pipeline to discover genomic variations overlapping the target regions of commonly used PCR primer sets. These are in a publicly available format based on a dataset of more than 1.2 million SARS-CoV-2 samples.
          </Card.Text>
          <div className="bottom-link"><a href="https://doi.org/10.1038/s41598-022-21953-3" aria-label="Read the paper in Nature">
           Read more
          </a></div>
        </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
        <Card.Subtitle className="text-black-50">Oct 2022 | Soiland-Reyes et al., Research Ideas and Outcomes</Card.Subtitle>
        <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Updating Linked Data practices for FAIR Digital Object principles</Card.Title>
        <Card.Text className="mb-5 mt-3">
         We believe that by adopting Linked Data principles, we can accelerate FAIR Digital Object (FDO) and start building practical ways to assist scientists in efficiently answering topical questions based on knowledge graphs.
        </Card.Text>
        <div className="bottom-link"><a href="https://doi.org/10.3897/rio.8.e94501" aria-label="Read the paper in Research Ideas and Outcomes">
          Read more
        </a></div>
      </Card.Body>
      </Card>
    </Col>
  </Row>
 <Row className="mt-4" mdxType="Row">
  <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
    <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
          <Card.Subtitle className="text-black-50">Oct 2022 | Soiland-Reyes et al., Research Ideas and Outcomes</Card.Subtitle>
          <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Creating lightweight FAIR Digital Objects with RO-Crate</Card.Title>
          <Card.Text className="mb-5 mt-3">
           RO-Crate is a lightweight method to package research outputs along with their metadata, based on Linked Data principles. We present how we have followed the FAIR Digital Object (FDO) recommendations and turned research outcomes into FDOs by publishing RO-Crates on the Web using HTTP.
          </Card.Text>
          <div className="bottom-link"><a href="https://doi.org/10.3897/rio.8.e93937" aria-label="Read the paper in Research Ideas and Outcomes">
           Read more
          </a></div>
        </Card.Body>
      </Card>
    </Col>
  <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
    <Card className="mb-4 mt-4 h-100" mdxType="Card">
      <Card.Body className="position-relative">
          <Card.Subtitle className="text-black-50">Oct 2022 | Bisognin et al., Mycobacteriology Spectrum</Card.Subtitle>
          <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Investigating M.chimaera contamination in heater-cooler units</Card.Title>
          <Card.Text className="mb-5 mt-3">
           We found highly similar genetic and phenotypic profiles of M. chimaera isolated from heater-cooler units (HCU) used during surgery to thermo-regulate patients' body temperature and from the same hospital tap water, suggesting the need for environmental surveillance and associated control measures.
          </Card.Text>
          <div className="bottom-link"><a href="https://doi.org/10.1128/spectrum.02893-22" aria-label="Read the paper in Mycobacteriology Spectrum">
           Read more
          </a></div>
        </Card.Body>
    </Card>
    </Col>
  </Row>
  <Row className="mt-4" mdxType="Row">
  <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
    <Card className="mb-4 mt-4 h-100" mdxType="Card">
      <Card.Body className="position-relative">
          <Card.Subtitle className="text-black-50">Oct 2022 | Zenodo preprint</Card.Subtitle>
          <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">The FAIR Cookbook - the essential resource for and by FAIR doers</Card.Title>
          <Card.Text className="mb-5 mt-3">
          We present the FAIR Cookbook, its creation and content, its value, use and adoptions, as well as the participatory process, collaborative plans for sustainability, and its adoption
          </Card.Text>
          <div className="bottom-link"><a href="https://doi.org/10.5281/zenodo.7696634" aria-label="Read the preprint in Zenodo">
           Read more
          </a></div>
        </Card.Body>
    </Card>
    </Col>
      <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
    <Card className="mb-4 mt-4 h-100" mdxType="Card">
        <Card.Body className="position-relative">
        <Card.Subtitle className="text-black-50">Oct 2022 | Angewandte Chemie</Card.Subtitle>
        <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Comprehensive Fragment Screening of the SARS-CoV-2 Proteome Explores Novel Chemical Space for Drug Development</Card.Title>
        <Card.Text className="mb-5 mt-3">
         The international Covid19-NMR consortium have identified binders targeting the RNA genome of SARS-CoV-2. We provide novel structural and chemical space for structure-based drug design against the SARS-CoV-2 proteome
        </Card.Text>
        <div className="bottom-link"><a href="https://doi.org/10.1002/anie.202205858" aria-label="Read the paper in Angewandte Chemie">
          Read more
        </a></div>
      </Card.Body>
      </Card>
    </Col>
  </Row>
  <Row mdxType="Row">
  <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
    <Card className="mb-4 mt-5 h-100" mdxType="Card">
        <Card.Body className="position-relative">
          <Card.Subtitle className="text-black-50">Aug 2022 | Scientific Data</Card.Subtitle>
          <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">A lightweight distributed provenance model</Card.Title>
          <Card.Text className="mb-5 mt-3">
           We define a lightweight provenance model enabling generation of distributed provenance chains in complex, multi-organizational environments.
          </Card.Text>
          <div className="bottom-link"><a href="https://doi.org/10.1038/s41597-022-01537-6" aria-label="Read the paper in Scientific Data">
           Read more
          </a></div>
        </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-5 h-100" mdxType="Card">
         <Card.Body className="position-relative">
        <Card.Subtitle className="text-black-50">July 2022 | Zenodo</Card.Subtitle>
        <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">COVID-19 vaccine effectiveness assessment - CDM specification</Card.Title>
        <Card.Text className="mb-5 mt-3">
         The Common Data Model specification of the BY-COVID project (WP5) on COVID-19 vaccine effectiveness in preventing SARS-CoV-2 infection.
        </Card.Text>
        <div className="bottom-link"><a href="https://doi.org/10.5281/zenodo.7572373" aria-label="Read the specifiction in Zenodo">
          Read more
        </a></div>
      </Card.Body>
      </Card>
  </Col>
  </Row>
  <Row className="mt-5" mdxType="Row">
  <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
    <Card className="mb-4 mt-4 h-100" mdxType="Card">
      <Card.Body className="position-relative">
        <Card.Subtitle className="text-black-50">May 2022 | arXiv</Card.Subtitle>
        <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Systemic barriers to pathogen-related data sharing</Card.Title>
        <Card.Text className="mb-5 mt-3">
         We report results of a study interviewing data professionals working with COVID-19-relevant data types including social media, mobility, viral genome, testing, infection, hospital admission and deaths.
        </Card.Text>
        <div className="bottom-link"><a href="https://doi.org/10.48550/arXiv.2205.12098" aria-label="Read the preprint at arXiv">
          Read more
        </a></div>
      </Card.Body>
    </Card>
    </Col>
      <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
    <Card className="mb-4 mt-4 h-100" mdxType="Card">
      <Card.Body className="position-relative">
        <Card.Subtitle className="text-black-50">Mar 2022 | Molecular Biology and Evolution</Card.Subtitle>
        <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Clusters of unusual mutational changes in Omicron lineage BA.1</Card.Title>
        <Card.Text className="mb-5 mt-3">
         We propose that mutations in three clusters interact to mitigate their individual fitness costs and adaptively alter the function of Spike.
        </Card.Text>
        <div className="bottom-link"><a href="https://doi.org/10.1093/molbev/msac061" aria-label="Molecular Biology and Evolution">
          Read more
        </a></div>
      </Card.Body>
    </Card>
    </Col>
  </Row>
  <Row mdxType="Row">
  <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
    <Card className="mb-4 mt-5 h-100" mdxType="Card">
        <Card.Body className="position-relative">
        <Card.Subtitle className="text-black-50">Mar 2022 | PLOS Computational Biology</Card.Subtitle>
        <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">10 Simple Rules for making a software tool workflow-ready</Card.Title>
        <Card.Text className="mb-5 mt-3">
         Workflows have become a core part of computational scientific analysis in recent years. This paper presents 10 simple rules for how a software tool can be prepared for workflow use.
        </Card.Text>
        <div className="bottom-link"><a href="https://doi.org/10.1371/journal.pcbi.1009823" aria-label="Read the paper in PLOS Computational Biology">
          Read more
        </a></div>
      </Card.Body>
      </Card>
    </Col>
      <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
    <Card className="mb-4 mt-5 h-100" mdxType="Card">
     <Card.Body className="position-relative">
          <Card.Subtitle className="text-black-50">Feb 2022 | Research Square preprint</Card.Subtitle>
          <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Host genomes for SARS-CoV-2 variant leaked into Antarctic soil</Card.Title>
          <Card.Text className="mb-5 mt-3">
           We follow up a report of a contaminated metagenomic sample set from Antarctica containing traces of unique SARS-CoV-2 variants. We identify genetic material from mitochondria of Homo sapiens, green monkey and Chinese hamster, the latter two probably originating from cell lines used for studying viruses.
          </Card.Text>
          <div className="bottom-link"><a href="https://doi.org/10.3233/DS-210053" aria-label="Read the paper in Data Science">
           Read more
          </a></div>
        </Card.Body>
    </Card>
    </Col>
  </Row>
  <Row className="mt-4" mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
    <Card className="mb-4 mt-5 h-100" mdxType="Card">
     <Card.Body className="position-relative">
          <Card.Subtitle className="text-black-50">Jan 2022 | Data Science</Card.Subtitle>
          <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Packaging research artefacts with RO-Crate</Card.Title>
          <Card.Text className="mb-5 mt-3">
           The aim of this paper is to introduce RO-Crate (an open, community-driven, and lightweight approach to packaging research artefacts along with their metadata in a machine readable manner) and assess it as a strategy for making multiple types of research artefacts FAIR.
          </Card.Text>
          <div className="bottom-link"><a href="https://doi.org/10.3233/DS-210053" aria-label="Read the paper in Data Science">
           Read more
          </a></div>
        </Card.Body>
    </Card>
    </Col>
      <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
    <Card className="mb-4 mt-5 h-100" mdxType="Card">
      <Card.Body className="position-relative">
        <Card.Subtitle className="text-black-50">Dec 2021 | Research Square Preprint</Card.Subtitle>
        <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Host genomes for SARS-CoV-2 variant leaked into Antarctic soil</Card.Title>
        <Card.Text className="mb-5 mt-3">
          We follow up a report of a contaminated metagenomic sample set from Antarctica containing traces of unique SARS-CoV-2 variants. We identify genetic material from mitochondria of Homo sapiens, green monkey and Chinese hamster, the latter two probably originating from cell lines used for studying viruses.
        </Card.Text>
        <div className="bottom-link"><a href="https://doi.org/10.21203/rs.3.rs-1177047/v1" aria-label="Read the paper on the ResearchSquare website">
          Read more
        </a></div>
      </Card.Body>
    </Card>
    </Col>
  </Row>
  <Row className="mt-4" mdxType="Row">
  <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-5 h-100" mdxType="Card">
       <Card.Body className="position-relative">
        <Card.Subtitle className="text-black-50">Dec 2021 | Figshare</Card.Subtitle>
        <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">The response of the scholarly communication system to the COVID-19 pandemic</Card.Title>
        <Card.Text className="mb-5 mt-3">
          This paper analyses how the scholarly communication system – involving the production, evaluation, and dissemination of research outputs – has responded to this crisis, focusing on the period until mid-2021.
        </Card.Text>
        <div className="bottom-link"><a href="https://doi.org/10.6084/m9.figshare.17125394.v1" aria-label="Read the report in Figshare">
          Read more
        </a></div>
      </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-5 h-100" mdxType="Card">
         <Card.Body className="position-relative">
          <Card.Subtitle className="text-black-50">Nov 2021 | Zenodo</Card.Subtitle>
          <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">FAIR, ethical, and coordinated data sharing for COVID-19 response</Card.Title>
          <Card.Text className="mb-5 mt-3">
           Data sharing is central to the rapid translation of research into advances in clinical medicine and public health practice. This paper is a review of COVID-19 data sharing platforms and registries.
          </Card.Text>
          <div className="bottom-link"><a href="https://doi.org/10.5281/zenodo.5642635" aria-label="Read the paper in Zenodo">
           Read more
          </a></div>
        </Card.Body>
      </Card>
  </Col>
  </Row>
  <Row className="mt-4" mdxType="Row">
  <Col className="align-items-stretch" xs={12} sm={12} lg={6} mdxType="Col">
      <Card className="mb-4 mt-5 h-100" mdxType="Card">
        <Card.Body className="position-relative">
        <Card.Subtitle className="text-black-50">September 2021 | Nature Biotechnology</Card.Subtitle>
        <Card.Title as="h3" className="mt-3 fw-bold fs-4 lh-sm">Ready-to-use public infrastructure for global SARS-CoV-2 monitoring</Card.Title>
        <Card.Text className="mb-5 mt-3">
         This paper presents the COVID-19 effort by the Galaxy Project, which pools free worldwide public computational infrastructure, making the analysis of deep sequencing data accessible to anyone while also providing an analytical framework for global pathogen genomic surveillance based on raw sequencing-read data.
        </Card.Text>
        <div className="bottom-link"><a href="https://doi.org/10.1038/s41587-021-01069-1" aria-label="Read the paper in Nature Biotechnology">
          Read more
        </a></div>
      </Card.Body>
      </Card>
    </Col>
  </Row>
          </Container>
          <span className="anchor" id="deliverables"></span>
          {/* Deliverables */}
          <h2>{`Deliverables and Milestones`}</h2>
          <p>{`Outputs are also published on the BY-COVID `}<a parentName="p" {...{
              "href": "https://zenodo.org/communities/bycovid/search?page=1&size=20"
            }}>{`Zenodo community`}</a>{`.`}</p>
          <br />
          <Tabs defaultActiveKey="deliverable" id="uncontrolled-tab-example" transition={true} className="mb-3" mdxType="Tabs">
  <Tab eventKey="deliverable" title="Deliverable" mdxType="Tab">
   <Table striped bordered mdxType="Table">
    <thead className="bg-white">
      <tr>
        <th>Code</th>
        <th>Due</th>
        <th>Description</th>
        <th>Responsibility</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>D8.1</td>
        <td>11/21</td>
        <td><a href="https://doi.org/10.5281/zenodo.6884734" aria-label="Read the deliverable in Zenodo" target="_blank">Project Handbook initial release and periodic updates</a></td>
        <td>WP8</td>
      </tr>
      <tr>
        <td>D3.1</td>
        <td>03/22</td>
        <td><a href="https://doi.org/10.5281/zenodo.6885016" aria-label="Read the deliverable in Zenodo" target="_blank">Metadata standards</a></td>
        <td>WP3</td>
      </tr>
      <tr>
        <td>D7.1</td>
        <td>03/22</td>
        <td><a href="https://doi.org/10.5281/zenodo.6884870" aria-label="Read the deliverable in Zenodo" target="_blank">Dissemination, exploitation and communication Plan</a></td>
        <td>WP7</td>
      </tr>
      <tr>
        <td>D8.2.1</td>
        <td>02/22</td>
        <td><a href="https://doi.org/10.5281/zenodo.6884816" aria-label="Read the deliverable in Zenodo" target="_blank">Project Data Management Plan initial release and periodic updates</a></td>
        <td>WP8</td>
      </tr>
      <tr>
        <td>D2.1</td>
        <td>06/22</td>
        <td><a href="https://doi.org/10.5281/zenodo.7017728" aria-label="Read the deliverable in Zenodo" target="_blank">Initial data and metadata harmonisation at domain level to enable fast responses to COVID-19</a></td>
        <td>WP2</td>
      </tr>
      <tr>
        <td>D1.1</td>
        <td>09/22</td>
        <td><a href="https://doi.org/10.5281/zenodo.7875986" aria-label="Read the deliverable in Zenodo" target="_blank">Extended workflows</a></td>
        <td>WP1</td>
      </tr>
      <tr>
        <td>D3.2</td>
        <td>09/22</td>
        <td><a href="https://doi.org/10.5281/zenodo.7129553" aria-label="Read the deliverable in Zenodo" target="_blank">Implementation of cloud-based, high performance, scalable indexing system</a></td>
        <td>WP3</td>
      </tr>
      <tr>
        <td>D8.2.2</td>
        <td>12/22</td>
        <td><a href="https://doi.org/10.5281/zenodo.7476926" aria-label="Read the deliverable in Zenodo" target="_blank">Project Data Management Plan initial release and periodic updates</a></td>
        <td>WP8</td>
      </tr>
      <tr>
        <td>D8.1.2</td>
        <td>03/23</td>
        <td><a href="https://doi.org/10.5281/zenodo.7789351" aria-label="Read the deliverable in Zenodo" target="_blank">Project Handbook initial release and periodic updates</a></td>
        <td>WP8</td>
      </tr>
      <tr>
        <td>D2.2</td>
        <td>06/23</td>
        <td><a href="https://zenodo.org/records/8101848" aria-label="Read the deliverable in Zenodo" target="_blank">Data Access and Transfer across research domains and jurisdictions</a></td>
        <td>WP2</td>
      </tr>
      <tr>
        <td>D1.2</td>
        <td>09/23</td>
        <td><a href="https://zenodo.org/records/10069940" aria-label="Read the deliverable in Zenodo" target="_blank">Preparedness Data Hub</a></td>
        <td>WP1</td>
      </tr>
      <tr>
        <td>D3.3.1</td>
        <td>09/23</td>
        <td><a href="https://zenodo.org/records/8386828" aria-label="Read the deliverable in Zenodo" target="_blank">COVID-19 Data Portal</a></td>
        <td>WP3</td>
      </tr>
      <tr>
        <td>D7.3</td>
        <td>09/23</td>
        <td><a href="https://zenodo.org/records/8405780" aria-label="Read the deliverable in Zenodo" target="_blank">Report on public engagement activities</a></td>
        <td>WP7</td>
      </tr>
      <tr>
        <td>D5.3</td>
        <td>11/23</td>
        <td><a href="https://zenodo.org/records/13330037" aria-label="Read the deliverable in Zenodo" target="_blank">Hot Spot detection, samples data collection and mechanistic analyses</a></td>
        <td>WP5</td>
      </tr>
      <tr>
        <td>D1.3</td>
        <td>03/24</td>
        <td><a href="https://zenodo.org/records/13323331" aria-label="Read the deliverable in Zenodo" target="_blank">Tracking and open analytics tools</a></td>
        <td>WP1</td>
      </tr>
      <tr>
        <td>D2.3</td>
        <td>03/24</td>
        <td><a href="https://zenodo.org/records/13255875" aria-label="Read the deliverable in Zenodo" target="_blank">Enabling data discovery at source using beacon-like mechanisms</a></td>
        <td>WP2</td>
      </tr>
      <tr>
        <td>D4.3</td>
        <td>03/24</td>
        <td><a href="https://zenodo.org/records/10927253" aria-label="Read the deliverable in Zenodo" target="_blank">Provenance model</a></td>
        <td>WP4</td>
      </tr>
      <tr>
        <td>D7.2</td>
        <td>03/24</td>
        <td><a href="https://zenodo.org/records/13889809" aria-label="Read the deliverable in Zenodo" target="_blank">Public report showcasing industry value from infectious disease data</a></td>
        <td>WP7</td>
      </tr>
      <tr>
        <td>D4.2</td>
        <td>04/24</td>
        <td><a href="https://zenodo.org/records/12706837" aria-label="Read the deliverable in Zenodo" target="_blank">Common analysis environment</a></td>
        <td>WP4</td>
      </tr>
      <tr>
        <td>D5.1</td>
        <td>05/24</td>
        <td><a href="https://zenodo.org/records/13870075" aria-label="Read the deliverable in Zenodo" target="_blank">Enriched report viral variants and health outcomes</a></td>
        <td>WP5</td>
      </tr>
      <tr>
        <td>D4.1</td>
        <td>06/24</td>
        <td><a href="https://zenodo.org/records/13909280" aria-label="Read the deliverable in Zenodo" target="_blank">Infectious diseases toolkit</a></td>
        <td>WP4</td>
      </tr>
      <tr>
        <td>D3.3.2</td>
        <td>07/24</td>
        <td><a href="https://zenodo.org/records/13332975" aria-label="Read the deliverable in Zenodo" target="_blank">COVID-19 Data Portal</a></td>
        <td>WP3</td>
      </tr>
      <tr>
        <td>D6.1</td>
        <td>07/24</td>
        <td><a href="https://zenodo.org/records/12820912" aria-label="Read the deliverable in Zenodo" target="_blank">Stakeholder engagement report</a></td>
        <td>WP6</td>
      </tr>
      <tr>
        <td>D6.2</td>
        <td>07/24</td>
        <td><a href="https://zenodo.org/records/13349254" aria-label="Read the deliverable in Zenodo" target="_blank">The training efforts report</a></td>
        <td>WP6</td>
      </tr>
      <tr>
        <td>D8.2.3</td>
        <td>07/24</td>
        <td><a href="https://zenodo.org/records/13981358" aria-label="Read the deliverable in Zenodo" target="_blank">Project Data Management Plan initial release and periodic updates</a></td>
        <td>WP8</td>
      </tr>
      <tr>
        <td>D8.3</td>
        <td>07/24</td>
        <td><a href="https://zenodo.org/records/13920061" aria-label="Read the deliverable in Zenodo" target="_blank">Report on sustainability plans</a></td>
        <td>WP8</td>
      </tr>
      <tr>
        <td>D2.4</td>
        <td>09/24</td>
        <td><a href="https://zenodo.org/records/13981571" aria-label="Read the deliverable in Zenodo" target="_blank">Report on data sources discovery and integration for enabling data use and re-use in response to future outbreaks</a></td>
        <td>WP2</td>
      </tr>
      <tr>
        <td>D5.2</td>
        <td>09/24</td>
        <td><a href="https://zenodo.org/records/13981769" aria-label="Read the deliverable in Zenodo" target="_blank">Secondary use of vaccine trial data and biosamples</a></td>
        <td>WP5</td>
      </tr>
      <tr>
        <td>D8.1.3</td>
        <td>09/24</td>
        <td><a href="https://zenodo.org/records/13985857" aria-label="Read the deliverable in Zenodo" target="_blank">Project Handbook initial release and periodic updates</a></td>
        <td>WP8</td>
      </tr>
    </tbody>
   </Table>
  </Tab>
  <Tab eventKey="milestone" title="Milestone" mdxType="Tab">
   <Table striped bordered mdxType="Table">
    <thead className="bg-white">
      <tr>
        <th>Code</th>
        <th>Due</th>
        <th>Description</th>
        <th>Responsibility</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>M7.1</td>
        <td>10/21</td>
        <td>Branding and communications guidelines
          <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP7</td>
      </tr>
      <tr>
        <td>M7.2</td>
        <td>11/21</td>
        <td>Launch of project website
          <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP7</td>
      </tr>
      <tr>
        <td>M8.1</td>
        <td>11/21</td>
        <td>Project mobilised. All governing boards and WPs established
          <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP8</td>
      </tr>
      <tr>
        <td>M8.2</td>
        <td>02/22</td>
        <td>DMP approved by the relevant project boards before submission
          <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span> 
        </td>
        <td>WP8</td>
      </tr>
      <tr>
        <td>M1.1</td>
        <td>03/22</td>
        <td>First support services in operation
          <span className="checkmark">
              <div className="checkmark_circle"></div>
              <div className="checkmark_stem"></div>
              <div className="checkmark_kick"></div>
            </span>
        </td>
        <td>WP1</td>
      </tr>
      <tr>
        <td>M2.1</td>
        <td>03/22</td>
        <td>Identified data sources have been registered in the BY-COVID reference catalogue
          <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP2</td>
      </tr>
      <tr>
        <td>M5.1</td>
        <td>02/22</td>
        <td>Compiled research questions and requirements Workshop 1
          <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP5</td>
      </tr>
      <tr>
        <td>M6.1</td>
        <td>03/22</td>
        <td>Stakeholder engagement (initial scoping and draft monitoring approach)
          <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP6</td>
      </tr>
      <tr>
        <td>M5.4</td>
        <td>09/22</td>
        <td>FAIR open-source pipeline
        <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP5</td>
      </tr>
      <tr>
        <td>M6.2</td>
        <td>09/22</td>
        <td>Identified training needs and roadmap 
          <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP6</td>
      </tr>
      <tr>
        <td>M7.3</td>
        <td>09/22</td>
        <td>Industry sector mapping report
          <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP7</td>
      </tr>
      <tr>
        <td>M4.1</td>
        <td>09/22</td>
        <td>Common analysis environment
        <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP4</td>
      </tr>
      <tr>
        <td>M4.2</td>
        <td>09/22</td>
        <td>Prototype Infectious diseases toolkit
        <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP4</td>
      </tr>
      <tr>
        <td>M2.2</td>
        <td>01/23</td>
        <td>Identified the preferred mechanisms for data access and use of Real-world data
        <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP2</td>
      </tr>
      <tr>
        <td>M1.2</td>
        <td>03/23</td>
        <td>First globally comprehensive data set
        <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP1</td>
      </tr>
      <tr>
        <td>M3.1</td>
        <td>03/23</td>
        <td>Initial set of resources metadata mapped, indexed, and discoverable in COVID-19 Data Portal
        <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP3</td>
      </tr>
      <tr>
        <td>M5.2</td>
        <td>03/23</td>
        <td>Compiled research questions and requirements Workshop 2
        <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP5</td>
      </tr>
      <tr>
        <td>M5.5</td>
        <td>03/23</td>
        <td>Viral variant and health outcomes
        <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP5</td>
      </tr>
      <tr>
        <td>M5.3</td>
        <td>03/24</td>
        <td>Compiled research questions and requirements Workshop 3
        <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP5</td>
      </tr>
      <tr>
        <td>M2.3</td>
        <td>07/24</td>
        <td>Report on upgrade of clinical trial data and metadata
        <span className="checkmark">
            <div className="checkmark_circle"></div>
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </td>
        <td>WP2</td>
      </tr>
    </tbody>
   </Table>
  </Tab>
          </Tabs>
          {/*<Table className="bg-white mb-5">
            <tbody>
              <tr>
                <td>All deliverables will appear here as they are published2.</td>
              </tr>
            </tbody>
           </Table>*/}
        </Col>
      </Row>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      